import '@elastic/eui/dist/eui_theme_light.css';
import '@elastic/charts/dist/theme_only_light.css';

import { EuiIcon, EuiImage, EuiPageTemplate, EuiProvider } from '@elastic/eui';
import Sidebar from './components/Sidebar';
import { useState } from 'react';
import GdpEurope from './components/pages/GdpEurope';


function App() {

  const [view, changeView] = useState("GDP - EU")
  
  return (
    <div className="App">
      <EuiProvider colorMode="light">
      <EuiPageTemplate
        panelled={true}
        bottomBorder={true}
        grow={true}
        
      >
          <EuiPageTemplate.Sidebar sticky={true}>
            <Sidebar handleItemSelected={(e)=>changeView(e)}/>
          </EuiPageTemplate.Sidebar>
        
          <EuiPageTemplate.Header
            pageTitle={<>
              <EuiImage
                size={"s"}
                src="./DeloitteSmallLogo.png"
                alt="dt logo"
              />
              
              MarketIntel
            </>}
            rightSideItems={[<EuiIcon type="help" />, <EuiIcon type="user" />]}
          />
        
          
          {view === "GDP - EU" ? <GdpEurope />: null}
          
          
      </EuiPageTemplate>
      </EuiProvider>

      
      
    </div>
  );
}

export default App;
