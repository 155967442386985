import { EuiButton, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiTitle } from "@elastic/eui";
import React, { Fragment, useEffect, useState } from "react";
import GridLayout from "react-grid-layout";

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import Stat from "../charts/Stat";
import * as EuroJSONstat from "jsonstat-euro";
import LineChart from "../charts/LineChart";

const localStorageKey = "ca-layout-gdp-eu"

const defaultlayout = [
    { i: "gdp-growth", x: 0, y: 0, w: 5, h: 5, static: true },
    { i: "gdp-per-capita", x: 5, y: 0, w: 5, h: 5, static: true },
    { i: "gva", x: 0, y: 5, w: 5, h: 5, static: true },
    { i: "gdp-per-capita-eu27-relative", x: 5, y: 5, w: 5, h: 5, static: true },    
    //{ i: "c", x: 7, y: 6, w: 1, h: 2, static: true }
]

const EU_COUNTRY_OPTIONS = [
    {
        label: "Malta",
        value: "MT"
    },
    {
        label: "EU 27",
        value: "EU27_2020"
    },
    {
        label: "EU 28",
        value: "EU28"
    },
    {
        label: "EU 15",
        value: "EU15"
    },
    {
        label: "Euro Area",
        value: "EA"
    },
    {
        label: "Belgium",
        value: "BE"
    },
    {
        label: "Bulgaria",
        value: "BG"
    },
    {
        label: "Czech Republic",
        value: "CZ"
    },
    {
        label: "Denmark",
        value: "DK"
    },
    {
        label: "Germany",
        value: "DE"
    },
    {
        label: "Estonia",
        value: "EE"
    },
    {
        label: "Ireland",
        value: "IE"
    },
    {
        label: "Greece",
        value: "EL"
    },
    {
        label: "Spain",
        value: "ES"
    },
    {
        label: "France",
        value: "FR"
    },
    {
        label: "Italy",
        value: "IT"
    },
    {
        label: "Croatia",
        value: "HR"
    },
    {
        label: "Cyprus",
        value: "CY"
    },
    {
        label: "Latvia",
        value: "LV"
    },
    {
        label: "Lithuania",
        value: "LT"
    },
    {
        label: "Hungary",
        value: "HU"
    },
    {
        label: "Luxembourg",
        value: "LU"
    },
    {
        label: "Netherlands",
        value: ""
    },
    {
        label: "Austria",
        value: "AT"
    },
    {
        label: "Poland",
        value: "PL"
    },
    {
        label: "Portugal",
        value: "PT"
    },
    {
        label: "Romania",
        value: "RO"
    },
    {
        label: "Slovenia",
        value: "SL"
    },
    {
        label: "Slovakia",
        value: "SK"
    },
    {
        label: "Finland",
        value: "FI"
    },
    {
        label: "Sweden",
        value: "SE"
    },
    {
        label: "Iceland",
        value: "IS"
    },
    {
        label: "Liechtenstein",
        value: "LI"
    },
    {
        label: "Norway",
        value: "NO"
    },
    {
        label: "Switzerland",
        value: "CH"
    },
    {
        label: "United Kingdom",
        value: "UK"
    },
    {
        label: "Montenegro",
        value: "´ME"
    },
    {
        label: "North Macedonia",
        value: "MK"
    },
    {
        label: "Albania",
        value: "AL"
    },
    {
        label: "Serbia",
        value: "RS"
    },
    {
        label: "Türkiye",
        value: "Tk"
    },
    {
        label: "Bosnia and Herzegovina",
        value: "BA"
    }
]



const saveToLS = (key, value) => {
    if (global.localStorage) {
      global.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          [key]: value
        })
      );
    }
}
  
const loadFromLS = (key) => {
    if (global.localStorage) {

        let savedLayout = JSON.parse(global.localStorage.getItem(
            localStorageKey,      
        ))
            
        if (savedLayout && savedLayout.layout) {
            return savedLayout.layout
        }               

        return defaultlayout
    }
}

const GdpEurope= () => {

    const [geoFilter, changeGeoFilter] = useState({
        label: "Malta",
        value: "MT"
    })
    const [yearFilter, changeYearFilter] = useState({
        label: "2021",
        value: "2021"
    })

    const [allowEdit, changeAllowEdit] = useState(false)
    const [layout, changeLayout] = useState(loadFromLS());
    
    const [gdpGrowth_data, changeGdpGrowth_data] = useState([])
    const [gdpPerCapita_data, changeGdpPerCapita_data] = useState([])
    const [gdpPerCapitaEU27_data, changeGdpPerCapitaEU27_data] = useState([])    
    const [gva_data, changeGva_data] = useState([])

    const currentYear = new Date().getFullYear();
    const last15Years = [];
    for (let i = 0; i < 15; i++) {
        last15Years.push({
            label: (currentYear - i).toString(),
            value: (currentYear - i).toString()
        });
    }
    
    const handleNewLayout = (newLayout) => {    
        if (!newLayout) {
            return
        }

        changeLayout( newLayout );    
    }    

    useEffect(() => {
        if (!layout) {
            return
        }
        
        let newLayout = layout.map(item => ({
            ...item,
            static: !allowEdit
        }))
        
        changeLayout(newLayout)
    }, [allowEdit])
    

    // GDP Growth Rate
    useEffect(() => {
        if (!geoFilter) {
            return
        }
        const query={
            dataset: "nama_10_gdp", 
            filter: {       
              geo: [geoFilter.value],
              na_item: ["B1GQ"], // Gross domestic product at market prices
              unit: ["CON_PPCH_PRE"] // Contribution to GDP growth, percentage point change on previous period
            }
          }
          
          EuroJSONstat.fetchDataset(query).then(ds=>{
              changeGdpGrowth_data(
                  ds.Dataset(0).toTable({ type: "arrobj" }).filter(datum => datum.value != null).map(datum => ({
                    x: datum.time,
                    y: datum.value
                  }))
              )
          });
    }, [geoFilter])

    // GDP Per Capita
    useEffect(() => {

        if (!geoFilter) {
            return
        }

        const query={
            dataset: "nama_10_pc", 
            filter: {       
              geo: [geoFilter.value],
              na_item: ["B1GQ"], // Gross domestic product at market prices
              unit: ["cp_eur_hab"] // Current Prices, Euro per Capita
            }
          }
          
        EuroJSONstat.fetchDataset(query).then(ds=>{            
            changeGdpPerCapita_data(
                ds.Dataset(0).toTable({ type: "arrobj" }).filter(datum => datum.value != null).map(datum => ({
                x: datum.time,
                y: datum.value
                }))
            )
          });
    }, [geoFilter])

    // GDP Per Capita relative to EU27
    useEffect(() => {

        if (!geoFilter) {
            return
        }

        const query={
            dataset: "nama_10_pc", 
            filter: {       
              geo: [geoFilter.value],
              na_item: ["B1GQ"], // Gross domestic product at market prices
              unit: ["pc_eu27_2020_hab_meur_cp"] // Percentage of EU27 (from 2020) total per capita (based on million euro, EU27 from 2020), current prices
            }
          }
          
        EuroJSONstat.fetchDataset(query).then(ds=>{            
            changeGdpPerCapitaEU27_data(
                ds.Dataset(0).toTable({ type: "arrobj" }).filter(datum => datum.value != null).map(datum => ({
                x: datum.time,
                y: datum.value
                }))
            )
          });
    }, [geoFilter])
    
    // Gross Value Added
    useEffect(() => {

        if (!geoFilter) {
            return
        }

        if (!yearFilter) {
            return
        }

        const query={
            dataset: "nama_10_a10", 
            filter: {       
              geo: [geoFilter.value],
              time:[yearFilter.value],
              na_item: ["B1G"], // Gross Value Addded
              unit: ["CP_MEUR"], // Current prices, in millions of EUR
              nace_r2: ["A", "B-E", "C", "F", "G-I", "J", "K", "L", "M_N", "O-Q", "R-U"]
            }
          }
          
        EuroJSONstat.fetchDataset(query).then(ds => {
              
              changeGva_data(
                  ds.Dataset(0).toTable({ type: "arrobj" }).filter(datum => datum.value != null).map(datum => ({
                      value: datum.value,
                      key: datum.nace_r2
                  }))
              )
        });
    }, [geoFilter, yearFilter])

    return <Fragment>
        <EuiPageTemplate.Section grow={false}>

            <EuiTitle style={{ textAlign: "center" }}>
              <h1>
                GDP EUROZONE
              </h1>
            </EuiTitle>

        </EuiPageTemplate.Section>

        <EuiPageTemplate.Section>
            <EuiFlexGroup direction="row" style={{padding: 10}}>
                <EuiFlexItem>
                    <EuiComboBox
                        aria-label="Country Selection"
                        placeholder="Select an EU country"
                        singleSelection={{ asPlainText: true }}
                        options={EU_COUNTRY_OPTIONS}
                        selectedOptions={geoFilter ? [geoFilter] : []}
                        onChange={(e) => {
                            if (e.length === 0) {
                                changeGeoFilter()
                                return
                            }
                            changeGeoFilter(e[0])
                        }}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiComboBox
                        aria-label="Year Selection"
                        placeholder="Select a year of Interest"
                        singleSelection={{ asPlainText: true }}
                        options={last15Years}
                        selectedOptions={yearFilter ? [yearFilter] : []}
                        onChange={(e) => {
                            if (e.length === 0) {
                                changeYearFilter()
                                return
                            }
                            changeYearFilter(e[0])
                        }}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            {

                geoFilter && yearFilter ? 
                <div style={{
                    background: allowEdit ? "lightgrey" : null,
                    height: "100%",
                    width: window.innerWidth * 0.7
                }}>
                    <GridLayout
                        className="layout"
                        layout={layout}
                        rowHeight={80}
                        cols={10}
                        width={window.innerWidth * 0.7}
                        onLayoutChange={handleNewLayout}                    
                    >
                        <div key="gdp-growth">                    
                            <BarChart title="GDP Growth" data={gdpGrowth_data} seriesName="% change" leftAxisName="% point change" bottomAxisName="year"/>     
                        </div>
                        <div key="gdp-per-capita">                    
                            <BarChart title="GDP Per Capita" data={gdpPerCapita_data} seriesName="euro per capita" leftAxisName="EUR per capita" bottomAxisName="year"/>
                        </div>
                        <div key="gva">
                            <PieChart title={`Gross Value Added By Industry over ${yearFilter.value}`} data={gva_data} />
                        </div>
                        <div key="gdp-per-capita-eu27-relative">
                            <LineChart title="GDP Per Capita - % of EU27" data={gdpPerCapitaEU27_data} seriesName="euro per capita" leftAxisName="EUR per capita" bottomAxisName="year"/>
                        </div>
                        {/* <div key="c">
                            <Stat />
                        </div> */}
                    </GridLayout>
                
                <EuiButton onClick={() => {
                    if (allowEdit) {
                        saveToLS('layout', layout)                    
                    }                
                    changeAllowEdit(!allowEdit)
                }}>
                    {allowEdit ? "Save Layout" : "Edit Layout"}
                    </EuiButton>
                    
                </div>
                    :
                    null
            }
            
        </EuiPageTemplate.Section>
    </Fragment>
}

export default GdpEurope