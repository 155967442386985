import { Chart, Partition, PartitionLayout, Settings } from "@elastic/charts";
import { EuiTitle } from "@elastic/eui";
import React from "react";

const defaultData = [
    {
        value: 1,
        key: "key1"
    },
    {
        value: 2,
        key: "key2"
    },
    {
        value: 3,                            
        key: "key3"
    },
    
]
const COLORS = ['#5798B2', '#2A9D8F', '#E9C46A', '#F4A261', '#E76F51']


const PieChart = ({title = "Pie Chart", data = defaultData}) => {
    return (
        <div style={{
            paddingTop: 20,
            height: "100%",
            width: "100%"
        }}>
            <EuiTitle size="s">
                <h1>
                    {title}
                </h1>                
            </EuiTitle>
            <Chart>
                <Settings
                    theme={{
                        partition: {
                            linkLabel: { maxCount: 15 },
                        },
                    }}
                />
                <Partition
                    id="spec_1"
                    data={data}
                    layout={PartitionLayout.sunburst}
                    valueAccessor={(d) => d.value}
                    layers={[
                        {
                        groupByRollup: (d) => d.key,
                        nodeLabel: (d) => d,
                        shape: {
                            fillColor: (d, index, a) => COLORS[index % COLORS.length],
                          },
                        },
                    ]}
                />
            </Chart>
        </div>
        
    );
}
  
export default PieChart