import React, { Fragment } from "react";

import { EuiFieldSearch, EuiIcon, EuiTitle, EuiTreeView } from '@elastic/eui';
import { useEffect, useState } from 'react';

  
const Sidebar = ({ handleItemSelected = () => null }) => {
    
    const defaultMenuItems = [
        {
          label: 'Economy',
          id: 'economy-sidebar',
          icon: <EuiIcon type="arrowRight" />,
          iconWhenExpanded: <EuiIcon type="arrowDown" />,
          isExpanded: true,
          children: [
            {
                label: 'GDP - Global',
                id: 'GDP - Global',
                icon: <EuiIcon type="document" />,                
                callback: () => {
                    handleItemSelected('GDP - Global')
                }
            },
            {
                label: 'GDP - EU',
                id: 'GDP - EU',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('GDP - EU')
                }
            },
            {
                label: 'GDP - Local',
                id: 'GDP - Local',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('GDP - Local')
                }
            },
            {
                label: 'Consumer Price Index',
                id: 'CPI-sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Consumer Price Index')
                }
            },
            {
                label: 'Unemployment',
                id: 'Unemployment-sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Unemployment')
                }
            },
            {
                label: 'Interest rates',
                id: 'Interest rates - sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Interest rates')
                }
            },
            {
                label: 'Retail sales',
                id: 'Retail sales-sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Retail sales')
                }
            },
            {
                label: 'Housing market',
                id: 'Housing market-sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Housing market')
                }
            },
            {
                label: 'Stock Market Indicators',
                id: 'smi-sidebar',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Stock Market Indicators')
                }
            },
            {
                label: 'Sentiment Surveys',
                id: 'Sentiment Surveys',
                icon: <EuiIcon type="document" />,
                callback: () => {
                    handleItemSelected('Sentiment Surveys')
                }
            }
          ],
        },
        {
          label: 'Government',
          id: 'government-sidebar',
          icon: <EuiIcon type="arrowRight" />,
          iconWhenExpanded: <EuiIcon type="arrowDown" />,
          children: []
        },
        {
          label: 'Business',
          id: 'Business-sidebar',
          icon: <EuiIcon type="arrowRight" />,
          iconWhenExpanded: <EuiIcon type="arrowDown" />,
          children: []
        },
        {
          label: 'ESG',
          id: 'esg-sidebar',
          icon: <EuiIcon type="arrowRight" />,
          iconWhenExpanded: <EuiIcon type="arrowDown" />,
          children: []
        },
        {
          label: 'Hospitality',
          id: 'hospitality-sidebar',
          icon: <EuiIcon type="arrowRight" />,
          iconWhenExpanded: <EuiIcon type="arrowDown" />,
          children: []
        }
    ]

    const [menuSearch, changeMenuSearch] = useState()
    const [menuItems, changeMenuItems] = useState(defaultMenuItems)

    useEffect(() => {
        if (menuSearch === "") {
        changeMenuItems(defaultMenuItems)
        }
        if (menuSearch) {
        changeMenuItems(defaultMenuItems.filter(item => 
            item.children.filter(child => child.label.includes(menuSearch)).length > 0
        ).map(item => {
            let filteredChildren = item.children.filter(child => child.label.includes(menuSearch))
            return {
            ...item,
            children: filteredChildren
            }
        })
        )}
    }, [menuSearch])
        
    return <Fragment>
        <EuiTitle size='xs'>
            <h1>
            Menu
            </h1>              
        </EuiTitle>            
        <EuiFieldSearch
            placeholder="Search"
            value={menuSearch}
            isClearable={true}
            onChange={(e) => changeMenuSearch(e.target.value)}
        />
        <EuiTreeView              
            items={menuItems}
        />
    </Fragment>
}

export default Sidebar