import { Axis, Chart, LineSeries, Position, ScaleType, Settings, Tooltip, TooltipType } from "@elastic/charts";
import { EuiTitle } from "@elastic/eui";
import React from "react";

const defaultData = [
    { x: 0, y: 2 },
    { x: 1, y: 7 },
    { x: 2, y: 3 },
    { x: 3, y: 6 },
]


const LineChart = ({title="Line Chart", data = defaultData, seriesName = "data", leftAxisName = "Left Axis", bottomAxisName = "Bottom Axis"}) => {
    return (
        <div style={{
            height: "100%",
            width: "100%"
        }}>
            <EuiTitle size="s">
                <h1>
                    {title}
                </h1>                
            </EuiTitle>
            <Chart>
                <Settings   
                    showLegend showLegendExtra legendPosition={Position.Right}    
                    onBrushEnd={e => console.log(e)}
                    chartMargins={{ top: 10, left: 10, bottom: 10, right: 10 }}
                />
                <Tooltip type={TooltipType.Crosshairs} snap={true} />
                <Axis id="bottom" position={Position.Bottom} title={bottomAxisName} showOverlappingTicks />
                <Axis id="left2" title={leftAxisName} position={Position.Left} tickFormat={d => Number(d).toFixed(2)} />
                <LineSeries
                    id={seriesName}
                    xScaleType={ScaleType.Linear}
                    yScaleType={ScaleType.Linear}
                    xAccessor={"x"}
                    yAccessors={['y']}
                    data={data}
                />
            </Chart>
        </div>
        
    );
}
  
export default LineChart